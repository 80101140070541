/******************************************
 *  Author : Suraj Sharma
 *  Created On : Mon Jan 04 2021
 *  File : FooterNavigator.jsx
 *******************************************/
import React from 'react';
import Link from 'next/link';
import { useSelector } from 'react-redux'
import { postAuthPaths } from '../../config/constants/NavigationConstants';

const FooterNavigator = ({pathname, ...props}) => {
    const { allowedTree } = useSelector((state) => state.treesReducer);
    const selectedColor = '#45AD45';
    return (
        <div className="floating-nav">
            {
                /* Navigation to Home Page */
            }
            <div className="tc tile">
                <Link href={ postAuthPaths.HOME_ALIAS }>
                    <figure className="mb-0 pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="196.14" height="186.45" viewBox="0 0 196.14 186.45">
                            <g id="Fill" transform="translate(-1.47 -4.03)">
                                <path 
                                    id="Path_32914" 
                                    data-name="Path 32914" 
                                    d="M113.67,9.67a20.533,20.533,0,0,0-28.27,0L1.47,93.54l9.47,9.47L26.2,87.74v82.74a19.994,19.994,0,0,0,20,20H152.87a19.994,19.994,0,0,0,20-20V87.74l15.27,15.27,9.47-9.47ZM86.2,177.14V130.47h26.67v46.67Zm73.34-6.67a6.668,6.668,0,0,1-6.67,6.67H126.2v-60H72.87v60H46.2a6.668,6.668,0,0,1-6.67-6.67V74.4L94.8,19.07a6.675,6.675,0,0,1,9.43-.04l.04.04L159.54,74.4v96.07Z" 
                                    fill={pathname===postAuthPaths.HOME_ALIAS ? selectedColor : "#35625d"} 
                                />
                            </g>
                        </svg>
                    </figure>
                </Link>
            </div>
            {
                /* Navigation to Hub Page */
            }
            <div className="tc tile">
                <Link href={ postAuthPaths.BIO }>
                    <figure className="mb-0 pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="90.6" height="104.4" viewBox="0 0 90.6 104.4">
                            <g id="Group_3591" data-name="Group 3591" transform="translate(-18.7 -15)">
                                <path 
                                    id="Path_3144" 
                                    data-name="Path 3144" d="M30,49A34,34,0,1,0,64,15,34,34,0,0,0,30,49Zm60,0A26,26,0,1,1,64,23,26,26,0,0,1,90,49Z" 
                                    fill={pathname===postAuthPaths.BIO ? selectedColor : "#35625d"} />
                                <path 
                                    id="Path_3145" 
                                    data-name="Path 3145" d="M24.4,119.4a56.01,56.01,0,0,1,79.2,0l5.7-5.7a64.219,64.219,0,0,0-90.6,0Z" 
                                    fill={pathname===postAuthPaths.BIO ? selectedColor : "#35625d"} />
                            </g>
                        </svg>
                    </figure>
                </Link>
            </div>
            {
                /* Navigation to Forest Page */
            }
            <div className="tc center-tile f2">
                <Link href={ allowedTree === 0 ? `${postAuthPaths.FOREST}?app=react_app` : `${postAuthPaths.FOREST}?switcherState=RIGHT` } >
                    <div className="fab pointer">
                        <span>
                            <figure className="mb-0">
                                <svg xmlns="http://www.w3.org/2000/svg" width="198.998" height="144.289" viewBox="0 0 198.998 144.289">
                                        <g id="menu-3" transform="translate(-0.821 -27.791)">
                                            <g id="_256-Tree">
                                                <path 
                                                    id="Path_32915" data-name="Path 32915" d="M54.93,172.08V72.88L41.4,59.36" 
                                                    fill="none" 
                                                    stroke={pathname===postAuthPaths.FOREST ? selectedColor : "#35625d"} 
                                                    strokeLinejoin="round" 
                                                    strokeWidth="9.018" 
                                                />
                                                <line id="Line_7903" data-name="Line 7903" y1="18.04" x2="18.03" transform="translate(54.93 81.9)" 
                                                    fill="none" 
                                                    stroke={pathname===postAuthPaths.FOREST ? selectedColor : "#35625d"} 
                                                    strokeLinejoin="round" 
                                                    strokeWidth="9.018" />
                                                <line id="Line_7904" data-name="Line 7904" x1="18.04" y1="18.03" transform="translate(36.89 95.43)" 
                                                    fill="none" 
                                                    stroke={pathname===postAuthPaths.FOREST ? selectedColor : "#35625d"} 
                                                    strokeLinejoin="round" 
                                                    strokeWidth="9.018" />
                                                <line id="Line_7905" data-name="Line 7905" y1="13.52" x2="13.52" transform="translate(54.93 59.36)" 
                                                    fill="none" 
                                                    stroke={pathname===postAuthPaths.FOREST ? selectedColor : "#35625d"} 
                                                    strokeLinejoin="round" 
                                                    strokeWidth="9.018" />
                                                <circle id="Ellipse_670" data-name="Ellipse 670" cx="49.6" cy="49.6" r="49.6" transform="translate(5.33 32.3)" 
                                                    fill="none" 
                                                    stroke={pathname===postAuthPaths.FOREST ? selectedColor : "#35625d"} 
                                                    strokeLinejoin="round" 
                                                    strokeWidth="9.018" />
                                            </g>
                                            <g id="_256-Tree_1_">
                                                <path id="Path_32916" data-name="Path 32916" d="M145.71,172.08V72.88L132.18,59.36" 
                                                    fill="none" 
                                                    stroke={pathname===postAuthPaths.FOREST ? selectedColor : "#35625d"} 
                                                    strokeLinejoin="round" 
                                                    strokeWidth="9.018" />
                                                <line id="Line_7906" data-name="Line 7906" y1="18.04" x2="18.03" transform="translate(145.71 81.9)" 
                                                    fill="none" 
                                                    stroke={pathname===postAuthPaths.FOREST ? selectedColor : "#35625d"} 
                                                    strokeLinejoin="round" 
                                                    strokeWidth="9.018" />
                                                <line id="Line_7907" data-name="Line 7907" x1="18.04" y1="18.03" transform="translate(127.67 95.43)" 
                                                    fill="none" 
                                                    stroke={pathname===postAuthPaths.FOREST ? selectedColor : "#35625d"} 
                                                    strokeLinejoin="round" 
                                                    strokeWidth="9.018" />
                                                <line id="Line_7908" data-name="Line 7908" y1="13.52" x2="13.52" transform="translate(145.71 59.36)" 
                                                    fill="none" 
                                                    stroke={pathname===postAuthPaths.FOREST ? selectedColor : "#35625d"} 
                                                    strokeLinejoin="round" 
                                                    strokeWidth="9.018" />
                                                <circle id="Ellipse_671" data-name="Ellipse 671" cx="49.6" cy="49.6" r="49.6" transform="translate(96.11 32.3)" 
                                                    fill="none" 
                                                    stroke={pathname===postAuthPaths.FOREST ? selectedColor : "#35625d"} 
                                                    strokeLinejoin="round" 
                                                    strokeWidth="9.018" />
                                            </g>
                                        </g>
                                    </svg>
                            </figure>
                        </span>
                        {
                            /**
                             * Based on client requirement Non-chosen Tree Notification Icon : https://app.clickup.com/t/ex4rnm
                             * We wont display badge when the user is present in forest page
                             */
                             pathname!==undefined && !pathname.includes(postAuthPaths.FOREST) && (
                                <span className="badge">{ allowedTree > 0 ? allowedTree: 0 }</span>
                             )
                        }
                    </div>
                </Link>
            </div> 
            {
                /* Navigation to Vlog Page */
            }
            <div className="tc tile">
                <Link href={postAuthPaths.VLOG}>
                    <figure className="mb-0 pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="195.73" height="135.98" viewBox="0 0 195.73 135.98">
                            <g id="menu-4" transform="translate(-2.13 -31.34)">
                                <path 
                                    id="Path_32917" 
                                    data-name="Path 32917" 
                                    d="M99.99,42.91c.41,0,40.78,0,67.44,1.92l1.17.11c3.56.32,7.4.69,11.17,4.59h0c1.78,1.78,3.81,8.08,4.5,12.58a278.7,278.7,0,0,1,1.78,29.73V106.5a279.073,279.073,0,0,1-1.78,29.75c-.66,4.4-2.74,10.78-4.48,12.46h0c-3.75,3.88-7.69,4.25-11.16,4.59l-1.21.12c-26.19,1.78-65.64,1.96-67.42,1.96-2.05,0-49.82-.48-65.07-1.78-.91-.14-1.78-.27-2.81-.37-4.06-.48-8.67-1.03-11.98-4.47h0c-1.78-1.78-3.81-8.06-4.48-12.54a278.7,278.7,0,0,1-1.78-29.73V91.97a278.542,278.542,0,0,1,1.78-29.73c.69-4.54,2.76-10.84,4.5-12.58h0c3.77-3.9,7.7-4.25,11.17-4.59l1.17-.11c26.69-1.9,67.03-1.92,67.42-1.92h.05m.02-11.57h0s-41.09,0-68.47,1.96c-3.83.44-12.15.48-19.57,8.17C6.1,47.47,4.17,60.8,4.17,60.8A289.875,289.875,0,0,0,2.13,91.97v14.66a287.256,287.256,0,0,0,1.96,31.3s1.9,13.33,7.78,19.18c7.46,7.69,17.22,7.46,21.58,8.27,15.66,1.48,66.55,1.94,66.55,1.94s41.12,0,68.52-2.01c3.83-.44,12.17-.5,19.57-8.19,5.85-5.85,7.78-19.18,7.78-19.18a287.3,287.3,0,0,0,1.99-31.3V91.99a287.256,287.256,0,0,0-1.96-31.3s-1.9-13.33-7.78-19.2c-7.44-7.69-15.78-7.74-19.57-8.17-27.44-1.98-68.56-1.98-68.56-1.98v.13Z" 
                                    fill={pathname===postAuthPaths.PORTAL ? selectedColor : "#35625d"} />
                                <path 
                                    id="Path_32918" 
                                    data-name="Path 32918" 
                                    d="M82.43,70.17V128.5l49.8-29.16Z" 
                                    fill={pathname===postAuthPaths.PORTAL ? selectedColor : "#35625d"} />
                            </g>
                        </svg>
                    </figure>
                </Link>
            </div>
            {
                /* Navigation to Diners Page */
            }
            <div className="tc tile">
                <Link href={ postAuthPaths.DINERS }>
                    <figure className="mb-0 pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="110.49" height="151.19" viewBox="0 0 110.49 151.19">
                            <g id="menu-5" transform="translate(-40.04 -24.42)">
                                <path 
                                    id="Path_32919" 
                                    data-name="Path 32919" 
                                    d="M144.72,123.28l-19.35-18.79a15.8,15.8,0,0,1-3.91-10.4V82.57c0-28.76,18.36-55.45,18.36-55.45l4.9,3.11Z" 
                                    fill={pathname===postAuthPaths.DINERS ? selectedColor : "#35625d"} />
                                <circle 
                                    id="Ellipse_672" 
                                    data-name="Ellipse 672" cx="5.81" cy="5.81" r="5.81" transform="translate(138.91 24.43)" 
                                    fill={pathname===postAuthPaths.DINERS ? selectedColor : "#35625d"} />
                                <path 
                                    id="Path_32920" 
                                    data-name="Path 32920" d="M150.53,30.24H138.9l-5.81,58.15v78.5a8.728,8.728,0,0,0,8.72,8.72h0a8.728,8.728,0,0,0,8.72-8.72Z" 
                                    fill={pathname===postAuthPaths.DINERS ? selectedColor : "#35625d"} />
                                <path 
                                    id="Path_32921" 
                                    data-name="Path 32921" d="M87.07,27.01a3.188,3.188,0,0,0-6.32.6V61.38a3.751,3.751,0,0,1-7.48.41L69.43,27.25a3.184,3.184,0,0,0-3.17-2.83h-.1a3.185,3.185,0,0,0-3.17,2.84L59.15,61.8a3.752,3.752,0,0,1-7.48-.42V27.61a3.188,3.188,0,0,0-6.32-.6c-1.41,7.37-5.31,28.59-5.31,38.12,0,23.26,17.44,29.07,17.44,29.07v72.69a8.72,8.72,0,1,0,17.44,0V94.2s17.44-5.81,17.44-29.07C92.38,55.6,88.48,34.38,87.07,27.01Z" 
                                    fill={pathname===postAuthPaths.DINERS ? selectedColor : "#35625d"} />
                            </g>
                        </svg>
                    </figure>
                </Link>
            </div>
        </div>
    );
};

export default FooterNavigator;
